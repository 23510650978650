import { Box, Container } from "@chakra-ui/react"
import HomeAnimation from "@components/animations/HomeAnimation"
import HeadingText from "@components/headings/HeadingText"
import NormalHeading from "@components/headings/NormalHeading"
import StylizedHeading from "@components/headings/StylizedHeading"
import HeaderLayout from "@components/layout/HeaderLayout"
import { GroupLogos, PartnerLogos } from "@data/logos"
import { readItem, readItems } from "@directus/sdk"
import directusClient from "@utils/directus"
import { GetStaticProps, InferGetStaticPropsType, NextPage } from "next"
import dynamic from "next/dynamic"
import { useTranslations } from "next-intl"
import { NextSeo } from "next-seo"

const DynamicFooter = dynamic(() => import("@components/layout/Footer"), {
  ssr: false,
})
const DynamicGuarantee = dynamic(
  () => import("@components/animations/Guarantee"),
  { ssr: false }
)
const DynamicDrawbacks = dynamic(
  () => import("@components/blocks/home/Drawbacks"),
  {
    ssr: false,
  }
)
const DynamicBenefits = dynamic(
  () => import("@components/blocks/home/Benefits"),
  {
    ssr: false,
  }
)
const DynamicHomeFeatures = dynamic(
  () => import("@components/blocks/home/HomeFeatures"),
  { ssr: false }
)
const DynamicQRSamples = dynamic(
  () => import("@components/blocks/home/QRSamples"),
  {
    ssr: false,
  }
)
const DynamicStats = dynamic(
  () => import("@components/blocks/home/HomeStats"),
  {
    ssr: false,
  }
)
const DynamicGroupsLogoCarousel = dynamic(
  () => import("@components/blocks/home/HomeLogoCarousel"),
  {
    ssr: false,
  }
)

const DynamicScanOrderPay = dynamic(
  () => import("@components/blocks/home/ScanOrderPay"),
  {
    ssr: false,
  }
)

const Home: NextPage = ({
  page_data,
  samplesList,
  locales,
  locale,
}: InferGetStaticPropsType<typeof getStaticProps>) => {
  const t = useTranslations("home")

  return (
    <>
      <NextSeo
        title={page_data.meta_title}
        description={page_data.meta_description}
        canonical={`${process.env.NEXT_PUBLIC_SITE_URL}`}
        languageAlternates={locales.map((lang: string) => ({
          hrefLang: lang,
          href: `${process.env.NEXT_PUBLIC_SITE_URL}${
            lang === "ar" ? "/ar" : ""
          }`,
        }))}
        additionalMetaTags={[
          {
            name: "keywords",
            content: page_data.meta_keywords,
          },
        ]}
      />
      <HeaderLayout
        fullScreen
        backgroundColor={"brand.primary"}
      >
        <HomeAnimation />
      </HeaderLayout>

      <Container
        maxW={"container.xl"}
        px={{ base: 10, xl: 0 }}
      >
        <NormalHeading as={"h1"}>
          {t("group_logos_carousel_title")}
        </NormalHeading>
        <DynamicGroupsLogoCarousel logos={GroupLogos} />

        <Box position={"relative"}>
          <NormalHeading>{t("home_heading_title")}</NormalHeading>
          <HeadingText>{t("home_heading_text")}</HeadingText>
          <DynamicGuarantee
            w={40}
            h={40}
            position={{ base: "relative", xl: "absolute" }}
            right={0}
            top={0}
            m={"auto"}
            mt={{ base: 10, xl: "auto" }}
            loop={true}
            play={true}
          />
          <DynamicScanOrderPay />
        </Box>

        <NormalHeading>{t("drawbacks.traditional_paper_menus")}</NormalHeading>
        <StylizedHeading>{t("drawbacks.drawbacks_title")}</StylizedHeading>
        <DynamicDrawbacks />

        <NormalHeading>{t("benefits.digital_emenu")}</NormalHeading>
        <StylizedHeading>{t("benefits.benefits")}</StylizedHeading>
        <DynamicBenefits />

        <NormalHeading>{t("increase_revenue_capture")}</NormalHeading>
        <StylizedHeading>{t("digital_menu")}</StylizedHeading>
        <DynamicHomeFeatures />

        <NormalHeading>{t("our_leading_integrations")}</NormalHeading>
        <StylizedHeading>{t("partners")}</StylizedHeading>
        <DynamicGroupsLogoCarousel logos={PartnerLogos} />

        <NormalHeading>{t("flexible_design_customizations")}</NormalHeading>
        <StylizedHeading>{t("brand_guidelines")}</StylizedHeading>
        <DynamicQRSamples samplesList={samplesList} />

        <NormalHeading
          color={"brand.primary"}
          textTransform={"uppercase"}
        >
          {t("try")}{" "}
          <Box
            as={"span"}
            fontFamily={locale === "ar" ? "Arslan Wessam" : "Wild Spirit Alt"}
            fontWeight={400}
            fontSize={{ base: "4xl", md: "5xl", lg: "7xl" }}
            color={"brand.gray"}
            textTransform={"lowercase"}
            p={2}
          >
            {t("it_on")}{" "}
          </Box>
          {t("your_phone")}
        </NormalHeading>
        <DynamicStats
          clients={1500}
          users={"15M"}
        />
      </Container>
      <DynamicFooter />
    </>
  )
}

export default Home

export const getStaticProps: GetStaticProps = async ({ locale, locales }) => {
  // Page meta data
  const page_data = await directusClient
    .request(
      readItem("emenu_pages", 1, {
        fields: ["slug", { translations: ["*"] }],
      })
    )
    .then((res) => {
      const translations = res?.translations?.find(
        (t) => t.languages_id === locale
      )
      return {
        slug: res?.slug,
        meta_title: translations?.meta_title,
        meta_description: translations?.meta_description,
        meta_keywords: translations?.meta_keywords,
        title: translations?.title,
        content: translations?.content,
      }
    })

  // QR Samples
  const samplesList = await directusClient.request(
    readItems("emenu_samples", {
      limit: -1,
      filter: {
        status: {
          _eq: "published",
        },
      },
    })
  )

  return {
    props: {
      page_data,
      samplesList,
      locales,
      locale,
      messages: {
        ...require(`@messages/shared/${locale}.json`),
        ...require(`@messages/home/${locale}.json`),
      },
    },
  }
}
